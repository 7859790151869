import {JobRepository} from 'IndexedDB';
import Axios from './Axios';

const jobRepository = new JobRepository();

interface SearchJob {
    id: number;
    formatJobId: number;
    name: string;
    status: number;
    dateUpdated: string;
    MaxLayoutStatus: number;
}

/**
 *
 * @param {boolean} mock Default value is false
 *
 * @return {Promise}
 */
export const getNotesForCustomAddress = async (mock = false) => {
    if (mock) {
        return [
            {
                note: 'All freight charges will be billed as part of the final invoice for the job',
            },
            {note: 'A minimum delivery charge will be applied to this order'},
            {
                note: 'Please contact us prior to placing this order if you require an estimate of the freight charges',
            },
            {
                note: 'Please ensure that you have a way to unload your order, larger orders may require a forklift or crane.',
            },
        ];
    } else {
        const response = await Axios.instance().get(`updates`);

        if (response.data.success) {
            return response.data.updates;
        } else throw response.data.errors;
    }
};

interface JobSearchResponse {
    data: {
        jobs: {
            [key: number]: SearchJob;
        };
    };
}

export const searchJob = async (searchString: string, signal: AbortSignal) => {
    try {
        const response = await Axios.instance().post<JobSearchResponse>(
            `search`,
            {
                search: searchString,
            },
            {
                signal: signal,
            }
        );

        return response.data['data']['jobs'];
    } catch (err) {
        return [];
    }
};

export const deleteFile = async (file: string, jobId: number) => {
    const response = await Axios.instance().delete(
        `jobs/${jobId}/file/${file}`
    );

    if (response.data.success) {
        return response.data;
    }
};

export const copyJob = async (jobId: number) => {
    const response = await Axios.instance().get(`jobs/${jobId}/copy`);

    if (response.data.success) {
        return response.data;
    }
};

export const deleteJob = async (jobId: number) => {
    const response = await Axios.instance().delete(`jobs/${jobId}`);

    if (response.data.success) {
        await jobRepository.deleteJob(Number(jobId));
    }
};

interface ResponseData {
    success: boolean;
    message?: string;
}

export const submitJob = async (jobId: number): Promise<string[]> => {
    const response = await Axios.instance().post<ResponseData>(
        `jobs/${jobId}/submit`
    );

    jobRepository.deleteJob(jobId);

    let messages: string | string[] = 'Job Submitted';

    if (response.data.message) {
        messages = response.data.message;
    }

    if (!Array.isArray(messages)) {
        messages = [messages];
    }

    return messages;
};

export const unSubmitJob = async (jobId: number) => {
    await Axios.instance().post(`jobs/${jobId}/unsubmit`);
};

export const resetJobPrice = async (jobId: number) => {
    await Axios.instance().post(`job/${jobId}/resetPrice`);
};

export const resetJobPriceStatus = async (jobId: number) => {
    const response = await Axios.instance().get(
        `job/${jobId}/resetPriceStatus`
    );
    if (response.data.success) {
        return response.data.status;
    }
};
type ResponseType = {
    errors?: string;
    success: boolean;
};

export const addCouponToJob = async (
    jobId: number | string,
    couponCode: string
) => {
    const response: {data: ResponseType} = await Axios.instance().post(
        `job/${jobId}/coupon/${couponCode}`
    );
    if (response.data.success) {
        return;
    } else throw response.data.errors;
};

export const deleteCoupon = async (jobId: number | string) => {
    const response: {data: ResponseType} = await Axios.instance().post(
        `job/${jobId}/deleteCoupon/`
    );
    if (response.data.success) {
        return;
    } else throw response.data.errors;
};
